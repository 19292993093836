import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: {id: "UA-176417229-1"}
}, router)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


